<template>
  <book-list-common></book-list-common>
</template>

<script>
export default {
  name: "book_list",
  components: {
    BookListCommon: (resolve) => {
      require(["@/components/BookListCommon.vue"], resolve);
    },
  },
  watch: {},
  data() {
    return {
      crowd: null,

    };
  },
  computed: {},
  methods: {
    getScrollLeft(element) {
      if (element === window) {
        return Math.max(
            window.pageXOffset || 0,
            document.documentElement.scrollLeft
        );
      }
      return element.scrollLeft;
    },
  },
  mounted() {
    // if(window.name == ""){
    //   console.log("首次被加载");
    //   location.reload()
    //   window.name = "refresh";
    // }else if(window.name == "refresh"){
    //   console.log("页面被刷新");
    // }

    // if (window.performance.navigation.type == 1) {
    //   console.log("页面被刷新")
    // }else{
    //   console.log("首次被加载")
    //   location.reload()
    // }

  },
  created() {
    const _this = this;
    if (_this.$route.params && _this.$route.params.crowd) {
      _this.crowd = _this.$route.params.crowd;
    } else {
      _this.crowd = null;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.ScrollLeft = this.getScrollLeft(
        document.getElementsByClassName("aqt_su")[0]
    );
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.ScrollLeft) {
        setTimeout(function () {
          document.getElementsByClassName("aqt_su")[0].scrollLeft =
              vm.ScrollLeft;
        }, 50)
      }
    });
  },
};
</script>

